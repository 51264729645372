import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';


const TermsOfUse = () => {

    return (
        <>
            <SEO title="Terms Of Use" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne
                    title="Terms Of Use"
                    page="Terms Of Use"
                />
                <div className="section-padding privacy-policy-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="privacy-policy-content">
                                    <p className='mb-3'>Agreement between Nxgtech Technology and business or individual. The client/individual is subject to the following terms and conditions.</p>
                                    <h4>General Working Agreement</h4>
                                    <p>This document defines the terms and conditions of our working relationship. All projects or services that Nxgtech Technology (hereafter referred to as “Nxgtech Technology” or “we”) may be contracted to produce or provide for you (hereafter referred to as “you” or “the client”) will be subject to the following:
                                    </p>
                                    <h4>Summary</h4>
                                    <p>We will always do our best to fulfil your needs and meet your goals, but sometimes it’s best to have a few things written down so that we both know what’s what, who should do what and what happens if stuff goes wrong. In this contract you won’t find complicated legal terms or long passages of unreadable text. We have no desire to trick you into signing something that you might later regret. We do want what’s best for the safety of both parties, now and in the future.</p>
                                    <h4>IN SHORT</h4>
                                    <p>You are hiring us to design and develop a website for the estimated total price as outlined in our proposal. Of course it’s a little more complicated, but we’ll get to that.</p>
                                    <h4>WHAT DO BOTH PARTIES AGREE TO DO?</h4>

                                    <p>If we are designing your application we’ll create designs for the look-and-feel, layout and functionality of your website. This contract includes one main design plus the opportunity for you to make up to two rounds of revisions. If you’re not happy with the designs at this stage, you will pay us in full for all of the work that we have produced until that point and you may either cancel this contract or continue to commission us to make further design revisions at our standard design rates.</p>
                                    <h3>Getting Down To The Nitty Gritty</h3>
                                    <h4>Design</h4>
                                    <p>If we are designing your application we’ll create designs for the look-and-feel, layout and functionality of your website. This contract includes one main design plus the opportunity for you to make up to two rounds of revisions. If you’re not happy with the designs at this stage, you will pay us in full for all of the work that we have produced until that point and you may either cancel this contract or continue to commission us to make further design revisions at our standard design rates.</p>
                                    <h4>HTML and CSS Layout Templates</h4>
                                    <p className='mb-4'>If the project includes HTML markup and CSS templates, we’ll develop these using valid HTML and CSS code. The landscape of web browsers and devices changes regularly and our approach is to look forward, not back. With that in mind we will test all our markup and CSS in current versions of all major desktop browsers to ensure that we make the most from them. Users of older or less capable browsers or devices will experience a design that is appropriate to the capabilities of their software. We do not cater for people using Microsoft Internet Explorer 8 or lower and cannot predict the behaviour of that browser. We will also test that these templates perform well on Apple’s iPad.</p>
                                    <p className='mb-4'>We will not test old or abandoned browsers, for example Microsoft Internet Explorer 7 or 6 for Windows or Mac, previous versions of Apple’s Safari, Mozilla Firefox or Opera unless otherwise specified. If you need us to consider these older browsers, we will charge you at our standard old browser rate for any necessary additional design work, development and testing.</p>
                                    <h4>Text Content</h4>
                                    <p className='mb-3'>We may have written a best-seller but we’re not responsible for writing or inputting any text copy unless we specified it in the original estimate. We’ll be happy to help though, and in addition to the estimate we will charge you at our standard copy writing or content input rate.</p>
                                    <h4>Photographs</h4>
                                    <p className='mb-3'>You will supply us photographs in digital format. If you choose to buy stock photographs we can suggest vendors of stock photography. Any time we spend searching for appropriate photographs will be charged at our standard discovery rate.</p>
                                    <h4>Changes and Revisions</h4>
                                    <p className='mb-3'>We know from plenty of experience that fixed-price contracts are rarely beneficial to you, as they often limit you to your first idea about how something should look, or how it might work. We don’t want to limit either your options or your opportunities to change your mind.</p>

                                    <p className='mb-3'>The estimate/quotation prices at the beginning of this document are based on the amount of work we estimate we’ll need to accomplish everything that you have told us you want to achieve. If you do want to change your mind, add extra pages or templates or even add new functionality, that won’t be a problem. However, you will be charged accordingly and these additional costs will need to be agreed to before the extra work commences. This additional work will affect deadlines and they will be moved accordingly. We’ll be up front about all of this if and when it happens to make sure we’re all on the same page before proceeding. We may also ask you to put requests in writing so we can keep track of changes. If the nature or functions of the project change significantly throughout the process, we reserve the right to deem the current project cancelled. At this point you will pay us in full for all the work we have done and may commission us to complete the new project based on the new requirements. This will require a new quote and contract.</p>
                                    <h4>Technical Support</h4>
                                    <p className='mb-3'>You may already have professional website hosting, you might even manage that hosting in-house; if that’s the case, great. If you don’t manage your own website hosting, or your current hosting environment does not support the solution we are providing, we can set up an account for you at one of our preferred, third-party hosting providers. We will charge you a one-off fee for installing your site on this server, plus any statistics software such as Google Analytics, then the updates to, and management of that server, plus any support issues will be up to you.</p>

                                    <p className='mb-3'>We are not a website hosting company and so do not offer or include technical support for website hosting, email or other services relating to website hosting.</p>
                                    <h4>Legal stuff</h4>
                                    <p className='mb-3'>We can’t guarantee that the functions contained in any web page templates or in a completed website will always be error-free and so we can’t be liable to you or any third party for damages, including lost profits, lost savings or other incidental, consequential or special damages arising out of the operation of or inability to operate this website and any other web pages, even if you have advised us of the possibilities of such damages.</p>

                                    <p className='mb-3'>If any provision of this agreement shall be unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable from this agreement and shall not affect the validity and enforceability of any remaining provisions.</p>
                                    <h4>Copyrights</h4>

                                    <p className='mb-3'>You guarantee to us that any elements of text, graphics, photos, designs, trademarks, or other artwork that you provide us for inclusion in the website are either owned by your good selves, or that you have permission to use them.</p>

                                    <p className='mb-3'>When we receive your final payment, copyright is automatically assigned as follows:</p>

                                    <p className='mb-3'>You own the graphics and other visual elements that we create for you for this project. We’ll give you a copy of all files and you should store them really safely as we are not required to keep them or provide any native source files we used to make them.</p>

                                    <p className='mb-3'>You also own text content, photographs and other data you provided, unless someone else owns them. We own the markup, CSS and other code and we license it to you for use on only this project.</p>

                                    <p className='mb-3'>We love to show off our work and share what we have learned with other people, so we reserve the right to display and link to your completed project as part of our portfolio and to write about the project on websites, in magazine articles and in books about web design.</p>
                                    <h4>Payments</h4>
                                    <p>We are sure you understand how important it is as a small business that you pay the invoices that we send you promptly. As we’re also sure you’ll want to stay friends, you agree to stick tight to the following payment schedule.</p>

                                    <h4>But Where’s All The Fine Print?</h4>
                                    <p>By starting a project with Nxgtech Technology  the client accepts these Terms and Conditions.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default TermsOfUse;