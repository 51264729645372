import React from "react";

const SectionTitle2 = ({
  subtitle,
  title,
  description,
  textAlignment,
  textColor,
}) => {
  return (
    <div>
      <div className="subtitle">
<center>
    <h4 className="title">
    What a Digital Marketing Services Company Can Do for Your Business
    </h4>
</center>

      </div>
      <h2 className="title"></h2>
      <p></p>
    </div>
  );
};

export default SectionTitle2;
