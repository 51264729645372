import React from "react";

const SectionTitleWhyChooseus = ({
  subtitle,
  title,
  description,
  textAlignment,
  textColor,
}) => {
  return (
    <div className={`section-heading`}>
      <div className="subtitle"></div>
      <h4 className="unique">Website Development Pricing * </h4>
    </div>
  );
};

export default SectionTitleWhyChooseus;
